<template>
  <div class="customer-support" v-if="customerSupportData">
    <HeroBanner
      v-if="customerSupportData.heroBanner"
      :isDecorated="true"
      :data="customerSupportData.heroBanner"
      :enableInnerImpressionPromotionAnalytics="false"
      :parentKey="customerSupportData.key"
    />
    <TextSection
      v-if="customerSupportData.paragraphB"
      :text="customerSupportData.paragraphB"
    />
    <section
      class="banners-section page-section"
      v-if="customerSupportData.infoBannerLeft || customerSupportData.infoBannerRight"
    >
      <div class="container">
        <div class="banners-section__wrap">
          <div
            v-if="customerSupportData.infoBannerLeft"
            class="banners-section__item banners-section__item--left"
            :style="`background-image: url('${infoBannerLeftImage}')`"
            @click="handleBannerClick(customerSupportData.infoBannerLeft.link)"
          >
            <i v-if="customerSupportData.infoBannerLeft.paragraph" class="banners-section__icon icon-exclamation-circle"></i>
            <div
              class="banners-section__paragraph"
              v-if="customerSupportData.infoBannerLeft.paragraph"
              v-html="customerSupportData.infoBannerLeft.paragraph"
            ></div>
          </div>
          <div
            v-if="customerSupportData.infoBannerRight"
            class="banners-section__item banners-section__item--right"
            :style="`background-image: url('${infoBannerRightImage}')`"
            @click="handleBannerClick(customerSupportData.infoBannerRight.link)"
          >
            <i v-if="customerSupportData.infoBannerRight.paragraph" class="banners-section__icon icon-file-alt"></i>
            <div
              class="banners-section__paragraph"
              v-if="customerSupportData.infoBannerRight.paragraph"
              v-html="customerSupportData.infoBannerRight.paragraph"
            ></div>
          </div>
        </div>
      </div>
    </section>
    <section class="contact-us-section page-section">
      <div class="container">
        <div class="contact-us-section__inner">
          <div
            class="paragraph"
            v-if="customerSupportData.contactUsText"
            v-html="customerSupportData.contactUsText"
          ></div>
          <button
            class="button button--primary"
            v-if="customerSupportData.contactUsButton"
            v-html="customerSupportData.contactUsButton.title"
            @click="navigateTo(customerSupportData.contactUsButton.url)"
          ></button>
        </div>
      </div>
    </section>
    <section class="questions-section page-section" v-if="customerSupportData.mostViewedQuestions">
      <div class="container">
        <div class="questions-section__content">
          <h2
            class="questions-section__title text-h3"
            v-if="customerSupportData.mostViewedQuestions.title"
            v-html="customerSupportData.mostViewedQuestions.title"
          ></h2>
          <Accordion
            v-for="item in customerSupportData.mostViewedQuestions.questionsList"
            :key="item.id"
          >
            <template v-slot:header>
              <h3 class="questions-section__item-title" v-html="item.header"></h3>
            </template>
            <template v-slot:content>
              <div
                class="questions-section__item-text paragraph"
                v-html="item.paragraph"
              ></div>
            </template>
          </Accordion>
        </div>
      </div>
    </section>
  </div>
  <loader v-else />
</template>

<script>
import { mapActions, mapState } from 'vuex'

import NavigationalMixin from '@/mixins/NavigationalMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import PageMixin from '@/mixins/PageMixin'
import TextSection from '@/components/TextSection'
import Accordion from '@/components/Accordion'
import HeroBanner from '@/components/HeroBanner'

export default {
  name: 'CustomerSupport',
  components: {
    TextSection,
    Accordion,
    HeroBanner
  },
  mixins: [PageMixin, NavigationalMixin, ScreenWidthMixin],
  computed: {
    ...mapState('customerSupport', ['customerSupportData']),
    infoBannerLeftImage () {
      return this.isMobile && this.customerSupportData.infoBannerLeft?.imageMobile
        ? this.customerSupportData.infoBannerLeft.imageMobile
        : this.customerSupportData.infoBannerLeft.image
    },
    infoBannerRightImage () {
      return this.isMobile && this.customerSupportData.infoBannerRight?.imageMobile
        ? this.customerSupportData.infoBannerRight.imageMobile
        : this.customerSupportData.infoBannerRight.image
    },
    pageTitle () {
      return this.customerSupportData?.meta?.title || this.$route.meta?.title
    },
    metaData () {
      return this.customerSupportData?.meta
    }
  },
  methods: {
    ...mapActions('customerSupport', ['setCustomerSupportData']),
    handleBannerClick (link) {
      if (link) this.navigateTo(link.url)
    }
  },
  async created () {
    if (!this.customerSupportData) await this.setCustomerSupportData()
    this.$nextTick(() => {
      this.setPageTitle(this.pageTitle)
      this.setMetaTags()
    })
  },
  beforeDestroy () {
    this.unsetMetaTags()
  }
}
</script>
